/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: "Bookmania";
  src: url('./fonts/Fontspring-DEMO-bookmania-regular.otf')
}

@font-face {
  font-family: "Bookmania-Bold";
  src: url('./fonts/Fontspring-DEMO-bookmania-bold.otf')
}

/* button1 Color Change */
.bg {
  animation: changebg 2s infinite;
}

@keyframes changebg {
  0% {
    background-color: #40d15d;
    color: black;
  }

  50% {
    background-color: #2d9242;
    color: black
  }

  100% {
    background-color: #40d15d;
    color: black;
  }
}



/* button2 Color Change */
.bg2 {
  animation: changebg2 2s infinite;
}

@keyframes changebg2 {
  0% {
    background-color: #151f28;
    color: white;
  }

  50% {
    background-color: #767B95;
    color: white
  }

  100% {
    background-color: #151f28;
    color: white;
  }
}